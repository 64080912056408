import React from 'react';
import moment from 'moment';
import { UI_DATE_FMT, UI_DATETIME_TZ } from "helpers/datetime";

export function Date({value}) {
	return (
		<time className="whitespace-nowrap" dateTime={value}>
			{moment(value).tz(UI_DATETIME_TZ).format(UI_DATE_FMT)}
		</time>
	);
}