import React, { useCallback, useContext, useMemo } from 'react';
import { Header, Segment } from "semantic-ui-react";
import { ListFilters, WrappedListPagination } from "xAppLib/DataTable";
import { obj_map } from "xAppLib/helpers/obj_map";
import { useViewport } from "xAppLib/Hooks";
import { useCurrent } from "xAppLib/Hooks/useCurrent";
import { isLargeScreen } from "../utils";

// Beyond here should probably be in something reusable, but just leaving here for now since it's not stable
export function FilterSegmentGroup({children, ...groupProps}) {
	const {width} = useViewport();

	return (
		<Segment.Group horizontal={isLargeScreen(width)} {...groupProps}>
			{children}
		</Segment.Group>
	);
}

const FilterContext = React.createContext(null);

export function FilterContextProvider({children, initialState = {}, options, labelMapFn}) {
	const [filters, setFilters] = React.useState(initialState);

	const labelMapFnRef = useCurrent(labelMapFn ?? (opt => opt.txt));

	const value = useMemo(() => ({
			filters,
			options: obj_map(
				options ?? {},
				(options, key) => options
					.sort((x, y) => y.tot - x.tot)
					.map(option => ({
						key: option.val,
						value: option.val,
						text: `${labelMapFnRef.current(option, key) ?? option.val} - ${option.tot}`,
					})),
			),

			setFilters,
			reset: () => setFilters(initialState),
		}),
		[filters, options],
	);

	return (
		<FilterContext.Provider value={value}>
			{children}
		</FilterContext.Provider>
	);
}

export function useFilters() {
	return useContext(FilterContext);
}

export function useFilter(name) {
	const context = useContext(FilterContext);
	const onFilterChange = useCallback((value) => {
		context.setFilters(f => ({
			...f,
			[name]: value[name] === '' ? undefined : value[name],
		}));
	}, [name]);

	return {
		name,
		value: context.filters[name],
		options: context.options?.[name],
		onFilterChange,
	};
}

export function Pagination({onChange, ...props}) {
	const {filters} = useFilters();

	const pageFilter = useFilter('page');
	const pageSizeFilter = useFilter('pageSize');

	const totalPages = Math.ceil(props.total / pageSizeFilter.value);

	return (<>
		<WrappedListPagination
			{...props}

			pages={totalPages}
			page={pageFilter.value}
			pageSize={pageSizeFilter.value}
			onPageSizeChange={pageSize => {
				pageSizeFilter.onFilterChange({pageSize});
				onChange?.({...filters, pageSize});
			}}
			onPageChange={page => {
				pageFilter.onFilterChange({page});
				onChange?.({...filters, page});
			}}
		/>
	</>);
}

export function Filters({children, onSearch, onReset, popup = true, start_open = true, btn_search = true, btn_reload = true}) {
	const context = useFilters();
	const refs = useCurrent({context, onReset, onSearch});

	const doReset = useCallback(() => {
		refs.current.context.reset();
		refs.current.onReset?.();
	}, []);

	const doSearch = useCallback(() => {
		refs.current.onSearch(refs.current.context.filters);
	}, []);

	return (
		<ListFilters onReset={doReset}
					 onSearch={doSearch}
					 {...{popup, start_open, btn_search, btn_reload}}
		>
			{children}
		</ListFilters>
	);
}

function DateFilter({name, title}) {
	const filter = useFilter(name);

	return (
		<Segment color="green">
			<Header>{title}</Header>
			<ListFilters.Filter {...filter} type="date"/>
		</Segment>
	);

}

function OptionsFilter({name, title}) {
	const filter = useFilter(name);

	return (
		<Segment color="green">
			<Header>{title}</Header>
			<ListFilters.Filter {...filter} as="dropdown"/>
		</Segment>
	);
}

function TextFilter({name, title, description}) {
	const filter = useFilter(name);

	return (
		<Segment color="green">
			<Header>{title}</Header>
			<ListFilters.Filter {...filter} as="text" fluid size="big"/>
			{description && <Header.Subheader>{description}</Header.Subheader>}
		</Segment>
	);
}

function BooleanButtonGroup({name, title}) {
	const filter = useFilter(name);

	return (
		<Segment color="green">
			<Header>{title}</Header>
			<ListFilters.Filter {...filter} type="boolean-button-group"/>
		</Segment>
	);
}

export const FreeformText = (props) => {
	const {
		title = 'Search Script Records',
		description = 'UR / SNUM / SID / Email / Mobile / DOB',
	} = props;

	return <TextFilter name={'usr_srch'} title={title} description={description}/>;
};

export const RequestingUser = () => <OptionsFilter name={'injector'} title={'Injector'}/>;

export const Doctor = () => <OptionsFilter name={'doc'} title={'Doctor'}/>;

export const DateFrom = () => <DateFilter name={'date_from'} title={'Date From'}/>;

export const DateTo = () => <DateFilter name={'date_to'} title={'Date To'}/>;

export const Organisation = () => <OptionsFilter name={'orgs'} title={'Organisation'}/>;

export const Status = () => <OptionsFilter name={'status'} title={'Status'}/>;

export const PostCare = () => <BooleanButtonGroup name={'postcare'} title={'Post-care photos/notes'}/>;

export const DtaStatus = () => <OptionsFilter name={'dta_status'} title={'DTA Status'}/>;