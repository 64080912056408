import React, {useState} from 'react'
import {Button, Modal} from "semantic-ui-react";
import Files from "xAppLib/Files/Files";
import API_service from "xAppLib/providers/API_service";

export function TreatmentAttachments({data}) {
	return (
		<div className="space-y-4">
			{data.is_script && <PaperScript sid={data.sid}/>}
			<Files
				scr_obj={data}
				size={'calc(100% / 8 - 2em)'}
				data={data['attch'] || []}
				mode='view_only'
				view_as='list'
			/>
		</div>
	)
}

function PaperScript({sid}) {
	const [show, setShow] = useState(false);
	const closeModal = () => setShow(false);
	const src = `${API_service.API_BASE_URL}viewScript?s=${sid}`;

	return (
		<>
			<Button content="Script"
					icon='file'
					as='a'
					compact
					labelPosition="left"
					onClick={() => setShow(true)}

					size='mini'
			/>
			<Modal open={!!show} onClose={closeModal} closeIcon>
				<Modal.Header>
					{show?.comn}
				</Modal.Header>
				<Modal.Content>
					<iframe src={src}
							style={{width: '100%', height: '70vh'}}/>
				</Modal.Content>
				<Modal.Actions>
					<Button basic content="Close" onClick={closeModal}/>
				</Modal.Actions>
			</Modal>
		</>
	)
}