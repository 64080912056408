import React from "react";
import { Icon } from "semantic-ui-react";
import { obj_map } from "../helpers/obj_map";
import { Flag_LevelColours, Flag_Levels } from "./UserFlag";

export const ANSWER_FLAGS = obj_map(
	{
		self_harm: {
			text: 'Suicide Risk / Self Harm',
			level: 'alert'
		},
		self_harm_warn: {
			text: 'Self Harm Warning',
			level: 'warn'
		},
		bdd: {
			text: 'BDD Discussion',
			level: 'info'
		},
		// Example flags that might be useful in future
		// first_time_used: {
		// 	text: 'First time used',
		// 	level: 'consult'
		// },
		// unsuitable: {
		// 	text: 'Unsuitable treatment',
		// 	level: 'warn'
		// },
	},
	(item) => ({
		...item,
		icon: {
			name: Flag_Levels[item.level],
			color: Flag_LevelColours[item.level]
		},
	})
);

function flagOrDefault(flg_id) {
	return ANSWER_FLAGS[flg_id] || {
		text: flg_id.toUpperCase(),
		level: 'warn',
		icon: {name: 'question', color: 'pink'}
	}
}

export function flagFormData(flg_id) {
	const flg = flagOrDefault(flg_id);

	return {f: flg_id, n: flg.text, l: flg.level};
}

export function AnswerFlag({flg_id, compact = false, ...props}) {
	const flg = flagOrDefault(flg_id);

	return <span {...props}><Icon name={flg.icon.name} color={flg.icon.color}/> {compact || flg.text}</span>
}
