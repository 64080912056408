import React from 'react';
import {Icon} from "semantic-ui-react";

const STATUS_MAP = status => {
	switch (status) {
		case 'await_doccall':
			return {
				label: 'Awaiting Doctor Call',
				colour: 'grey',
				icon: 'clock outline'
			};
		case 'in_doccall':
			return {
				label: 'In Consult',
				colour: 'blue',
				icon: 'doctor'
			};
		case 'done_doccall':
			return {
				label: 'Complete',
				colour: 'green',
				icon: 'check'
			};
		case 'cancelled':
			return {
				label: 'Cancelled',
				colour: 'red',
				icon: 'dont'
			};
		default:
			return {
				label: status,
				colour: 'pink',
				icon: 'question'
			};
	}
}

export function ConsultStatus({value}) {
	const {label, icon, colour} = STATUS_MAP(value);

	return (
		<span className="whitespace-nowrap">
			<Icon color={colour} name={icon}/> {label}
		</span>
	);
}