import React from "react";
import { Button, Popup } from "semantic-ui-react";
import { AnswersList } from "../../../xAppLib/Users/AnswersList";

export function FieldAnswersPopup({row}) {
	if (!row.answs) return null;

	return (
		<Popup position="bottom right"
			   trigger={<Button icon="list alternate outline"/>}
			   content={<AnswersList answs={row.answs}/>}
			   wide="very"
			   on={['click']}
		/>
	);
}