import moment from "moment/moment";
import React, { useMemo } from "react";
import { Button, Popup, Comment, Header, Divider } from "semantic-ui-react";

function parseRawNote(note) {
	return note.split('\n').reduce((notes, line) => {
		const [, tm, by] = /^(\d\d\/\d\d\/\d\d \d\d:\d\d) - (.*) :$/.exec(line) || [];

		if (notes.length === 0 || tm) {
			notes.push(
				tm ? {
					// the backend uses y/m/d despite everything else using d/m/y
					tm: moment.tz(tm, 'YY/MM/DD HH:mm', 'Australia/Melbourne').format('DD/MM/YY HH:mm'),
					by: by.split(' (')?.[0],
					lines: []
				} : {
					by: 'unknown',
					lines: [line]
				}
			);
		} else {
			notes[notes.length - 1].lines.push(line.trim());
		}

		return notes;
	}, []);
}

export function Notes({header, notes}) {
	return (
		<Comment.Group>
			{header ?? <Header as='h3' dividing>Notes</Header>}
			{notes.map((entry, i) => (
				<React.Fragment key={entry.tm + i}>
					{i > 0 && <Divider/>}
					<Comment>
						<Comment.Content>
							<Comment.Author>{entry.by}</Comment.Author>
							{entry.tm && <Comment.Metadata>{entry.tm}</Comment.Metadata>}
							<Comment.Text>
								{entry.lines.map((line, i) => <p key={i}>{line}</p>)}
							</Comment.Text>
						</Comment.Content>
					</Comment>
				</React.Fragment>
			))}
		</Comment.Group>
	);
}
export function RenderNotes({note, header, reverse}) {
	const entries = useMemo(() => {
		try {
			const notes = parseRawNote(note);

			if (notes.length === 0) return note;
			if (reverse) return notes.reverse();
			return notes;
		} catch (e) {
			// Fallback. We _could_ log this, but it's not really that important. If we spot it, we can investigate.
			return note;
		}
	}, [note, reverse]);

	if (Array.isArray(entries)) {
		return <Notes header={header} notes={entries}/>;
	}

	if (typeof entries === 'undefined') {
		return null;
	}

	return <pre>{entries}</pre>
}

export default function FieldMetaNotes({f: options = {}, row}) {
	const note = row?.meta?.note ?? row?.meta__note;
	if (!note) {
		return null;
	}

	const content = (
		<div style={{maxHeight: '70vh', paddingRight: '1rem', overflowX: 'hidden', overflowY: 'auto'}}>
			<RenderNotes note={note}/>
		</div>
	);

	const trigger = <Button icon={options.ico || 'file alternate outline'} compact={options.compact}/>;

	return <Popup trigger={trigger} content={content} wide='very' on={['click']}/>
}