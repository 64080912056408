import { useCallback, useEffect, useReducer, useRef } from "react";
import { useCurrent } from "./useCurrent";

/**
 * Just like `useAsync`, but defaults `immediate` to `false` i.e. not invoked on mount, you need to explicitly call the
 * function yourself (e.g. via submit handler or button click).
 *
 * @param {FetchFunction | UseAsyncConfig} loaderOrConfig
 * @param {Array<*>?} deps
 * @return {UseAsyncResult}
**/
export function useAsyncUpdate(loaderOrConfig, deps) {
	const config = makeConfig(loaderOrConfig, {immediate: false});

	return useAsync(config, deps);
}

/**
 *
 * @param {FetchFunction | UseAsyncConfig} loaderOrConfig
 * @param {Array<*>?} deps
 * @return {UseAsyncResult}

 * @example
 * // Basic example, loading static resource once, on mount
 * const { data: timeMelb, loading, error } = useAsync(() => API_service.api_promise('timeMelb'));

 * @example
 * // Loading resource whenever some prop changes
 * const { data: scr_obj, loading, error } = useAsync(() => sid && API_service.api_promise(`script/${sid}`), [sid]);

 * @example
 * // Loading based on user input with option to retry in case of error
 * const [id, setId] = useState(null);
 * const { loading, error, data, fn: load } = useAsync(() => id && API_service.api_promise('movie/${id}'), [id]);
 * return (<>
 *     <select onChange={({target:{value}}) => setId(value)}}>
 *         <option>--select--</option>
 *         {someListOfOptions().map(
 *         	opt => <option key={opt.id} value={opt.id}>{opt.label}</option>
 *         )}
 *     </select>
 *     {id && <>
 *         {loading && <>loading...</>}
 *         {data && <pre>{JSON.stringify(data)}</pre>}
 *         {error && <>That didn't work, <button onClick={load}>try again?</button></>}
 *     </>}
 * <>)
 */
export function useAsync(loaderOrConfig, deps = []) {
	const _cfg = makeConfig(loaderOrConfig);
	/** @type {AsyncState} */
	const initial = {loading: _cfg.immediate, seq: 0};
	const [state, dispatch] = useReducer(loaderReducer, null, () => initial);
	const config = useCurrent(_cfg);
	const sequence = useRef(0);

	const callApi = useCallback(async (...args) => {
		if (typeof config.current.fn === 'function') {
			const seq = ++sequence.current;
			dispatch({seq, loading: true, ...(config.current.keep ? {} : {data: undefined})});
			try {
				const data = await config.current.fn(...args);
				dispatch({seq, data});

				return data;
			} catch (error) {
				dispatch({seq, error});

				return Promise.reject(error);
			}
		}
	}, deps);

	useEffect(() => {
		if (config.current.immediate) {
			callApi().catch(() => {
				// ignore
			});
		}
	}, [_cfg.immediate, ...deps]);

	return {...state, fn: callApi};
}

function loaderReducer(state, { seq, ...action }) {
	if ('loading' in action) {
		return {
			...state,
			...action,
			error: undefined
		};
	}

	if (seq < state.seq) {
		// completion action is stale, keep more recent state
		return state;
	}

	if ('error' in action) {
		return {
			seq,
			loading: false,
			error: action.error
		}
	}

	if ('data' in action) {
		return {
			seq,
			loading: false,
			data: action.data
		}
	}

	return state;
}

/**
 * @param {FetchFunction | UseAsyncConfig} loaderOrConfig
 * @param {Partial<UseAsyncConfig>} overrides
 * @return {UseAsyncConfig}
**/
function makeConfig(loaderOrConfig, overrides = {}) {
	return {
		immediate: true,
		keep: true,
		...overrides,
		...(typeof loaderOrConfig === 'function' ? {fn: loaderOrConfig} : loaderOrConfig)
	}
}

/**
 * @typedef {AsyncState} UseAsyncResult
 * @property {FetchFunction} fn
 */

/**
 * @typedef {Object} AsyncState
 * @property {boolean} loading - if the request is inflight or not
 * @property {T} data - whatever the loading function resolves to (assuming it resolved)
 * @property {*} error - whatever the loading function rejected with (assuming it rejected)
 * @template T
 */

/**
 * @typedef {Object} UseAsyncConfig
 * @property {FetchFunction} fn - load a record
 * @property {boolean = false} immediate - if the function should be invoked on mount/deps change
 * @property {boolean = true} keep - keep existing value when re-fetching
 */

/**
 * @callback FetchFunction
 * @param {...*} args
 * @return {Promise<T>}
 * @template T
 */
