import React, { useMemo, useState } from 'react';
import { Link } from "react-router-dom";
import { Button, Dimmer, List, Loader, Segment, Table } from "semantic-ui-react";
import instcons_model from "models/instcons_model";
import scripts_list_model from "models/scripts_list_model";
import { DataShow } from "xAppLib/DataTable";
import { useAsync, useAsyncUpdate } from "xAppLib/Hooks/useAsync";
import { useViewport } from "xAppLib/Hooks";
import { obj_map } from "xAppLib/helpers/obj_map";
import Photos from "xAppLib/Photos/Photos";
import CopyField from "xAppLib/UIelems/CopyField";
import { useToggleByKey } from "../../scripts/hooks";
import { AsyncButton } from "../../UIelems/AsyncButton";
import FieldAnswerFlags from "../../UIelems/fields/AnswerFlags";
import { FieldAnswersPopup } from "../../UIelems/fields/FieldAnswersPopup";
import FieldMetaNotes from "../../UIelems/fields/FieldMetaNotes";
import { FieldRawPopup } from "../../UIelems/fields/FieldRawPopup";
import { ConsultStatus } from "./components/ConsultStatus";
import { DtaIndicator, labelFromOption } from "./components/DtaIndicator";
import {
	DateFrom,
	DateTo,
	Doctor, DtaStatus,
	FilterContextProvider,
	Filters,
	FilterSegmentGroup,
	FreeformText,
	Organisation,
	Pagination,
	PostCare,
	RequestingUser,
	Status,
	useFilters,
} from "./components/Filters";
import { TreatmentAttachments } from "./components/TreatmentAttachments";
import { TreatmentStatusIcon } from "./components/TreatmentStatus";
import { WrapComponent } from "./components/WrapComponent";
import { toDictionary } from "./utils";

const isLargeScreen = width => width >= 1024; // tailwindcss definition of large

const prepend_compound_jpath = (conf, prefix) => ({
	...conf,
	// oof. all the attributes we need are on `consult`, so prepend any jpath with `consult.`
	parts: conf.parts.map(conf => obj_map(conf, (value, key) => /^jpath/.test(key) ? `${prefix}.${value}` : value)),
});

const PAGE_SIZES = [5, 10, 20, 50];
const DEFAULT_PAGINATION = {page: 1, pageSize: 20};

const labelMapFn = (option, filterId) => (
	filterId === 'status' ? scripts_list_model.stats_dict(option.val) :
	filterId === 'dta_status' ? labelFromOption(option) :
	option.txt
);

export function ListTreatmentPlans(props) {
	const {fields, title, embedded, filters, showFilters = true, initialFilters, dataRowComponent: DataRow} = props;

	const [query, setQuery] = useState(() => ({...DEFAULT_PAGINATION, ...initialFilters}));

	const list = useAsync(async () => await instcons_model.list_treatment_plans(query), [query]);

	const {data, total_count} = list.data ?? {};

	const [dataRow, toggleDataRow] = useToggleByKey();

	return (
		<WrapComponent title={title} embedded={embedded}>
			<FilterContextProvider options={list.data?.filter_options}
								   initialState={query}
								   labelMapFn={labelMapFn}
			>
				{showFilters && (
					<Filters onSearch={updated => setQuery({...updated})}>
						{filters}
					</Filters>
				)}
				<Pagination
					onChange={list.fn}
					pageSizeOptions={PAGE_SIZES}
					loading={list.loading}
					data={data}
					total={total_count}
					showPageSizeSelector
				>
					<Segment basic style={{padding: 0}}>
						<Dimmer active={list.loading} inverted>
							<Loader/>
						</Dimmer>
						<Table striped>
							<Table.Header>
								<Table.Row>
									{fields.map(f => <Table.HeaderCell key={f.name}>{f.name}</Table.HeaderCell>)}
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{(data ?? []).map(plan => (
									<React.Fragment key={plan.consult.sid}>
										<Table.Row>
											{fields.map(f => (
												<Table.Cell key={f.path || f.name} verticalAlign="top">
													{DataShow.show_data_field(plan, f, null, null, null, null, {
														onToggleDataRow: (id) => toggleDataRow(plan.consult.sid, id),
														refetch: list.fn,
													})}
												</Table.Cell>
											))}
										</Table.Row>
										{dataRow[plan.consult.sid] && (
											<Table.Row>
												<Table.Cell colSpan={fields.length} verticalAlign="top">
													<DataRow row={plan} type={dataRow[plan.consult.sid]}/>
												</Table.Cell>
											</Table.Row>
										)}
									</React.Fragment>
								))}
								{data?.length === 0 && (
									<Table.Row>
										<Table.Cell colSpan={fields.length}>No data</Table.Cell>
									</Table.Row>
								)}
							</Table.Body>
						</Table>
					</Segment>
				</Pagination>

			</FilterContextProvider>
		</WrapComponent>
	);
}

const CopySnum = ({value}) => <CopyField val={value.snum} altval={value.sid}/>;
const CopyUr = ({value}) => <CopyField val={value.ur}/>;

function Stack({children}) {
	return (
		<div className="flex-vertical space-y-2 items-start">
			{children}
		</div>
	);
}

function ScriptButton({value, pathname = `/treatment/${value.sid}`}) {
	return (
		<Button
			as={Link}
			to={{pathname}}
			content="View"
			color="cta_tertiary"
			size="mini"
			compact
			icon="eye"
			className="whitespace-nowrap"
		/>
	);
}

function RenderTimeField({value}) {
	return (
		<Stack>
			<span>{value.add_tm}</span>
			<ScriptButton value={value}/>
		</Stack>
	);
}

function RenderAdminTimeField({value}) {
	return (
		<Stack>
			<span>{value.add_tm}</span>
			<ScriptButton pathname={`/cosmetics/consult/${value.sid}`} value={value}/>
			<CopySnum value={value}/>
			<CopyUr value={value}/>
		</Stack>
	);
}

function RenderMedicationField({value: treatments, row}) {
	const {width} = useViewport();

	return (<>
			<p><strong>{row.consult.med_db_data__name}</strong></p>
			<DtaIndicator value={row.consult}/>
			{!isLargeScreen(width) && <ConsultStatus value={row.consult.status}/>}
			<List>
				{Object.entries(treatments ?? {}).map(([mid, product]) => (
					<List.Item key={mid}>
						<List.Icon>
							<TreatmentStatusIcon status={product.status}/>
						</List.Icon>
						<List.Content className="!pl-0">
							<List.Header>{product.med_name}</List.Header>
							{product.locations.map(l => (
								<List.Description key={l.id} className="whitespace-nowrap">
									{l.location} {l.quantity}{l.units ?? ''}
								</List.Description>
							))}
						</List.Content>
					</List.Item>
				))}
			</List>
		</>
	);
}

function RenderAdminActionsField({row: {consult, actions = []}, refetch}) {
	const {filters} = useFilters();
	const promote = useAsyncUpdate(async () => {
		if ('yes' === await app.confirm('Confirm promotion', 'This will create a Direction To Administer (DTA) for this consult and will allow re-treatments without doctor approval?')) {
			return await instcons_model.promote_dta(consult.sid).then(() => refetch(filters)).catch(res => {
				app.trigger(app.events.SHOW_MESSAGE,
					'Promotion failed',
					res.error ?? 'Unknown error',
					'negative'
				);
			});
		}
	});

	return (
		<Stack>
			<Link to="/scripts/list/support" state={{srch: `${consult.sid}+`}} title="View consult and related scripts">
				Scripts List
			</Link>

			{actions.includes('promote_dta') && (
				<AsyncButton onClick={promote.fn}>Promote DTA</AsyncButton>
			)}
		</Stack>
	);
}

function RenderPhotosField({row, onToggleDataRow}) {
	const scripts = [row.consult].concat(row.treatments);
	const hasPhotos = scripts.some(scr => scr.phts?.length > 0);
	const hasAttach = scripts.some(scr => scr.attch?.length > 0);

	return (
		<Stack>
			{hasPhotos && (
				<Button
					icon="camera"
					onClick={() => onToggleDataRow('photos')}
				/>
			)}
			{hasAttach && (
				<Button
					icon="paperclip"
					onClick={() => onToggleDataRow('attachments')}
				/>
			)}
		</Stack>
	);
}

function RenderConsultHist({row}) {
	return (
		<Stack>
			<FieldRawPopup row={row}/>
			<FieldMetaNotes row={row.consult}/>
			<FieldAnswersPopup row={row.consult}/>
			<FieldAnswerFlags row={row.consult}/>
		</Stack>
	);
}

function PhotosRow({row}) {
	return (
		<Photos
			size={'calc(100% / 4 - 2em)'}
			data={row.consult.phts || []}
			mode="view_only"
		/>
	);
}

function AttachmentsRow({row}) {
	const scriptsWithAttachments = [row.consult].concat(row.treatments).filter(scr => scr.attch?.length > 0);
	return (
		<List divided>
			{scriptsWithAttachments.map(scr => (
				<List.Item key={scr.sid}>
					<List.Content>
						<List.Header>{scr.med_name}</List.Header>
						<TreatmentAttachments data={scr}/>
					</List.Content>
				</List.Item>
			))}
		</List>
	);

}

function AdminDataRow({row, type}) {
	if (type === 'photos') return <PhotosRow row={row}/>;
	if (type === 'attachments') return <AttachmentsRow row={row}/>;
	return null;
}

ListTreatmentPlans.Nurse = function NurseListTreatmentPlans(props) {
	const {width} = useViewport();

	const fields = useMemo(() => {
		const byName = toDictionary(scripts_list_model.DET_FIELDS_NURSE, field => field.name);
		return [
			{
				name: 'Time',
				jpath: 'consult',
				template: RenderTimeField,
			},
			prepend_compound_jpath(byName['Patient'], 'consult'),
			{
				name: 'Treatments',
				jpath: 'treatments',
				template: RenderMedicationField,
			},
			{
				...prepend_compound_jpath(byName['Pharmacy / Clinic'], 'consult'),
				name: 'Clinic',
				show: ({width}) => isLargeScreen(width),
			},
			{
				...byName['Doctor'],
				jpath: 'consult.doc_db_data__name',
				show: ({width}) => isLargeScreen(width),
			},
			{
				name: 'Status',
				jpath: 'consult.status',
				template: ConsultStatus,
				show: ({width}) => isLargeScreen(width),
			},
		].filter(f => f.show ? f.show({width}) : true);
	}, [width]);

	return (
		<ListTreatmentPlans
			fields={fields}
			title="View all your/clinic treatment plans"
			filters={<>
				<FilterSegmentGroup>
					<FreeformText description="Name / Email / Mobile / DOB"/>
					<DateFrom/>
					<DateTo/>
					<DtaStatus/>
				</FilterSegmentGroup>
				<FilterSegmentGroup>
					<Organisation/>
					<Doctor/>
					<RequestingUser/>
					<Status/>
				</FilterSegmentGroup>
			</>}
			{...props}
		/>
	);
};

ListTreatmentPlans.Admin = function AdminListTreatmentPlans() {
	const fields = useMemo(() => {
		const byName = toDictionary(scripts_list_model.DET_FIELDS_ADMIN, field => field.name);
		return [
			{
				name: 'Time',
				jpath: 'consult',
				template: RenderAdminTimeField,
			},
			prepend_compound_jpath(byName['Patient'], 'consult'),
			{
				name: 'Treatments',
				jpath: 'treatments',
				template: RenderMedicationField,
			},
			{
				...prepend_compound_jpath(byName['Pharmacy / Clinic'], 'consult'),
				name: 'Clinic',
			},
			prepend_compound_jpath(byName['Doctor'], 'consult'),
			{
				name: 'Hist',
				template: RenderConsultHist,
			},
			{
				name: 'Status',
				jpath: 'consult.status',
				template: ConsultStatus,
			},
			{
				name: 'Photos',
				jpath: 'consult',
				template: RenderPhotosField,
			},
			{
				name: 'Actions',
				template: RenderAdminActionsField,
			},
		];
	}, []);

	return (
		<ListTreatmentPlans
			fields={fields}
			title="Treatment Plans"
			dataRowComponent={AdminDataRow}
			filters={<>
				<FilterSegmentGroup>
					<FreeformText/>
					<DateFrom/>
					<DateTo/>
					<PostCare/>
					<DtaStatus/>
				</FilterSegmentGroup>
				<FilterSegmentGroup>
					<Organisation/>
					<Doctor/>
					<RequestingUser/>
					<Status/>
				</FilterSegmentGroup>
			</>}
		/>
	);
};

export default function ListTreatmentPlansWrapper({view, ...props}) {
	if (view === 'admin') return <ListTreatmentPlans.Admin {...props} />;
	if (view === 'nurse') return <ListTreatmentPlans.Nurse {...props} />;
	return null;
}
