import React from 'react';
import { Icon, List } from "semantic-ui-react";
import { AnswerFlag } from "./AnswerFlag";
import { sort_qs } from "../../views/med/utils";

export function AnswersList(
	{
		answs,
		highlightAnswer = [],
		relaxed = false,
		hideIcon = false,
		hideFlag = false,
		scrolling = true,
		...listProps
	}
) {
	const sorted = {}
	const sorted_keys = sort_qs(answs).map(a => a.k)
	sorted_keys.map(k => sorted[k] = answs[k])

	const val = Object.keys(sorted||{}).map(k => answs[k].a && (
		<List.Item key={k}><div>
			<span>{answs[k].q}</span>{" "}

			<b className="whitespace-nowrap" style={{ ...(highlightAnswer?.length > 0 && highlightAnswer.includes(answs[k].a) ? {background:'red',padding:'3px',color:'#fff'} : {}) }}>
				{answs[k].a}
				{!hideIcon && ['p', 'f'].includes(answs[k].r) && <> <Icon color={answs[k].r == 'p' && 'green' || 'red'} name={answs[k].r == 'p' && 'check' || 'close'}/> </>}
			</b>{" "}

			{!!answs[k].x && <><blockquote className="ml-4 my-2">{answs[k].x}</blockquote></>}

			{!hideFlag && answs[k].f && <div className="mb-2 ml-2"><AnswerFlag flg_id={answs[k].f.f} /></div>}
		</div></List.Item>
	));

	return <List style={scrolling ? {maxHeight: 300, overflowY: 'auto'} : {}} {...listProps} relaxed={relaxed} items={val} data-testid='list-answers'/>;
}
