import React from 'react';
import { Icon } from "semantic-ui-react";
import { Date } from "./Date";

const STATUS_MAP = status => {
	switch (status) {
		case 'expired':
			return {
				label: 'Expired',
				colour: 'grey',
				icon: 'file alternate outline',
			};
		case 'valid':
			return {
				label: 'Valid',
				colour: 'green',
				icon: 'file alternate outline',
			};
		case 'invalid':
			return {
				label: 'Invalid',
				colour: 'red',
				icon: 'file alternate outline',
			};
		case 'annulled':
			return {
				label: 'Annulled',
				colour: 'red',
				icon: 'file alternate outline',
			};
		default:
			return {
				label: status,
				colour: 'pink',
				icon: 'file alternate outline',
			};
	}
};

export function labelFromOption({val, txt}) {
	return STATUS_MAP(val).label ?? txt;
}

export function DtaIndicator({value}) {
	if (!value?.dta_status) {
		return null;
	}

	const {label, icon, colour} = STATUS_MAP(value.dta_status);
	const {validFrom, validUntil} = value.cosm_det.dta;

	return (
		<span className="whitespace-nowrap">
			<Icon color={colour} name={icon}/> {label}{Boolean(validFrom && validUntil) && (
			<>: <Date value={validFrom}/>&ndash;<Date value={validUntil}/></>
		)}
		</span>
	);
}