import React from "react";
import {Helmet} from "react-helmet";
import {Container} from "semantic-ui-react";

export function WrapComponent({embedded, children, title, as: ContainerComponent = Container}) {
	if (embedded) return <div>{children}</div>;

	return (
		<ContainerComponent className="wide-content pb-16" fluid>
			<Helmet>
				<title>{title}</title>
			</Helmet>

			<h1 className="text-center !text-5xl text-xl">{title}</h1>

			{children}
		</ContainerComponent>
	);
}

